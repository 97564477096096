import { Controller } from "@hotwired/stimulus"
import { getStoredSourcesForConcertID, clearSourcesForConcertID } from "@concerto/ticket_source_tracking"
import { getRandomMember } from "@concerto/utilities"

const UNKNOWN_SOURCE = "UNKNOWN"

export default class extends Controller {
  static targets = [ "loader", "form", "formWrapper", "notLoading" ]
  static classes = [
    "ticketName",
    "ticketType",
    "ticketDescription",
    "donationTicketPrice",
    "regularTicketPrice",
    "times",
    "ticketQuantity",
    "customDescription",
    "customPricing",
    "customPricingOuterWrapper",
    "hidden",
    "donationTicketType",
    "standardTicketType",
    "donationValue"
  ]
  
  static values = {
    emailPlaceholder: String,
    namePlaceholder: String,
    eventCode: String,
    concertId: String,
    source: { type: String, default: UNKNOWN_SOURCE },
    releases: String,
    removablePrefix: String,
    donationTicketName: String,
    redirectUrl: String,
    appendSlugToRedirect: Boolean
  }
  
  initialize() {
    this.restructured = false
    this.failsafe = null
    this.waitingPeriod = 0
    this.processedTicketTypes = []
    this.donationValueField = null
    this.donationQuantityFieldWrapper = null
    this.donationQuantityField = null
  }
  
  get tito() {
    return window.tito
  }
  
  formTargetConnected() {
    const elementID = this.formTarget.id
    this.elementID = elementID
    
    this.hideFormWrapperTarget()
    this.mountWidget()
    this.restructurePageOnWidgetLoad()
    this.attachRegistrationListener()
  }
  
  mountWidget() {
    this.tito("widget.mount", {
      el: `#${this.elementID}`,
      event: this.eventCodeValue,
      namePlaceholder: this.namePlaceholderValue,
      emailPlaceholder: this.emailPlaceholderValue,
      inline: true,
      source: this.pickSource(),
      releases: this.releasesValue === '*' ? null : this.releasesValue
    })

    this.failsafeWidgetLoad()
    this.clearSourcesAfterTicketsPurchased()
  }

  pickSource() {
    if (this.sourceValue && this.sourceValue !== UNKNOWN_SOURCE) {
      return this.sourceValue
    }

    const storedSources = getStoredSourcesForConcertID(this.concertIdValue)
    
    if (storedSources.length > 0) {
      const randomSource = getRandomMember(storedSources)
      return randomSource
    }

    return undefined
  }
  
  restructurePageOnWidgetLoad() {
    this.tito("on:widget:loaded", (_data) => {
      this.changeWidgetAsNeeded()
    })
  }

  changeWidgetAsNeeded() {
    const widgetDetailsHaveLoaded = this.checkWidgetDetailsHaveLoaded()

    if (widgetDetailsHaveLoaded) {
      // First load is itself just the loading symbol
      // The second load is the actual tickets
      this.restructureWidgetAndMakeVisible()
      this.removeFailsafe()
      this.hideWaitingMessage()
    }
  }

  failsafeWidgetLoad() {
    const waitingPeriodInMilliseconds = 500

    if (!this.failsafe && !this.restructured) {
      this.failsafe = setInterval(() => {
        this.changeWidgetAsNeeded()
      }, waitingPeriodInMilliseconds)
    }
  }

  hideWaitingMessage() {
    this.notLoadingTarget.classList.add(this.hiddenClass)
  }

  removeFailsafe() {
    if (this.failsafe && this.restructured) {
      clearTimeout(this.failsafe)
    }
  }
  
  checkWidgetDetailsHaveLoaded() {
    const widget = document.getElementsByClassName(this.ticketTypeClass)
    return widget.length > 0
  }
  
  hideFormWrapperTarget() {
    this.formWrapperTarget.classList.add(this.hiddenClass)
  }
  
  restructureWidgetAndMakeVisible() {
    if (!this.restructured) {
      this.processTicketTypes()
      this.hideLoader()
      this.unhideFormWrapperTarget()
      this.restructured = true
    }
  }

  clearSourcesAfterTicketsPurchased() {
    this.tito("on:registration:finished", () => {
      if (this.concertIdValue) {
        clearSourcesForConcertID(this.concertIdValue)
      }
    })
  }
  
  hideLoader() {
    this.loaderTarget.classList.add(this.hiddenClass)
  }
  
  unhideFormWrapperTarget() {
    this.formWrapperTarget.classList.remove(this.hiddenClass)
  }
  
  processTicketTypes() {
    const titoTickets = document.getElementsByClassName(this.ticketTypeClass)
    
    for (const ticketType of titoTickets) {
      this.restructureTicketType(ticketType)
    }
  }
  
  correctName(ticketTypeElement) {
    const ticketNameElement = ticketTypeElement.getElementsByClassName(this.ticketNameClass)[0]
    const baseTicketName = ticketNameElement.textContent.trim()

    if (this.removablePrefixValue) {
      const correctedTicketName = baseTicketName.replaceAll(this.removablePrefixValue, '')
      ticketNameElement.textContent = correctedTicketName
    }
  }

  getTicketNameFromElement(ticketTypeElement) {
    const ticketNameElement = ticketTypeElement.getElementsByClassName(this.ticketNameClass)[0]
    return ticketNameElement.textContent.trim()
  }

  getPriceElement(ticketTypeElement) {
    const donationPrice = ticketTypeElement.getElementsByClassName(this.donationTicketPriceClass)[0]
    const regularPrice = ticketTypeElement.getElementsByClassName(this.regularTicketPriceClass)[0]
    return donationPrice || regularPrice
  }
  
  restructureTicketType(ticketTypeElement) {
    const ticketName = this.getTicketNameFromElement(ticketTypeElement)

    if (!this.processedTicketTypes.includes(ticketName)) {
      this.correctName(ticketTypeElement)

      const donationTicket = this.donationTicketNameValue === ticketName

      if (donationTicket) {
        ticketTypeElement.classList.add(this.donationTicketTypeClass)
        // this.processDonationTicketTypeElement(ticketTypeElement)
      } else if (this.standardTicketTypeClass) {
        ticketTypeElement.classList.add(this.standardTicketTypeClass)
      }

      const description = ticketTypeElement.getElementsByClassName(this.ticketDescriptionClass)[0]
      const price = this.getPriceElement(ticketTypeElement)
      const times = ticketTypeElement.getElementsByClassName(this.timesClass)[0]
      const quantity = ticketTypeElement.getElementsByClassName(this.ticketQuantityClass)[0]
      
      const descriptionWrapper = document.createElement("div")
      descriptionWrapper.appendChild(description)
      descriptionWrapper.classList.add(this.customDescriptionClass)
      
      const pricingWrapper = document.createElement("div")

      pricingWrapper.appendChild(price)

      // if (!donationTicket) {
      pricingWrapper.appendChild(times)
      // }

      pricingWrapper.appendChild(quantity)
      
      pricingWrapper.classList.add(this.customPricingClass)
      
      const pricingOuterWrapper = document.createElement("div")
      pricingOuterWrapper.classList.add(this.customPricingOuterWrapperClass)
      pricingOuterWrapper.appendChild(pricingWrapper)

      ticketTypeElement.appendChild(descriptionWrapper)
      ticketTypeElement.appendChild(pricingOuterWrapper)

      this.processedTicketTypes.push(ticketName)
    }
  }

  attachRegistrationListener() {
    this.tito("on:registration:finished", (data) => {
      if (data && this.hasRedirectUrlValue) {
        const { finished, slug } = data

        if (finished && (!this.appendSlugToRedirectValue || slug)) {
          const redirectTo = this.appendSlugToRedirectValue ? `${this.redirectUrlValue}/${slug}` : this.redirectUrlValue
          Turbo.visit(redirectTo)
        }
      }
    })
  }

  /*
  processDonationTicketTypeElement(donationTicketTypeElement) {
    this.assignDonationFields(donationTicketTypeElement)

    if (this.donationQuantityFieldWrapper && this.donationQuantityField && this.donationValueField) {
      this.addHandlerToDonationValueField()
      // this.donationQuantityFieldWrapper.setAttribute("hidden", "hidden")
      this.donationQuantityFieldWrapper.classList.add(this.hiddenClass)
    }
  }

  assignDonationFields(donationTicketTypeElement) {
    const quantityWrappers = donationTicketTypeElement.getElementsByClassName(this.ticketQuantityClass)

    if (quantityWrappers.length > 0) {
      const targetDonationFieldWrapper = quantityWrappers[0]

      for (const child of targetDonationFieldWrapper.children) {
        if (child.nodeName === "INPUT" && child.type === "checkbox") {
          this.donationQuantityFieldWrapper = targetDonationFieldWrapper
          this.donationQuantityField = child
        }
      }
    }

    if (this.donationQuantityFieldWrapper) {
      const donationValueFields = donationTicketTypeElement.getElementsByClassName(this.donationValueClass)

      if (donationValueFields.length > 0) {
        this.donationValueField = donationValueFields[0]
      }
    }
  }

  addHandlerToDonationValueField() {
    this.donationValueField.addEventListener("input", (event) => {
      const { target: { value } } = event

      if (Number(value) > 0) {
        if (!this.donationQuantityField.checked) {
          this.donationQuantityField.click()
        }
      } else {  
        if (this.donationQuantityField.checked) {
          this.donationQuantityField.click()
        }
        // console.log('deactivation donation...')
        // this.donationQuantityField.removeAttribute("checked")
        // this.donationQuantityField.checked = false
      }
    })
  } */
}
